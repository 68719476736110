import { jsx as _jsx } from "react/jsx-runtime";
import React, { Suspense } from "react";
import { Loader, Route, Routes } from "components-care";
import { AnonRoutes, AuthRoutes, ExternalAuthenticatedRoutes } from "./Routes";
import AuthPageLayout from "./Auth/components/AuthPageLayout";
import AuthProvider from "./components/AuthProvider";
const Portal = React.lazy(() => import("./AdminUI"));
const AuthPages = () => {
    return (_jsx(AuthPageLayout, { children: _jsx(Suspense, { fallback: _jsx(Loader, {}), children: _jsx(Routes, { children: AuthRoutes.map((entry) => (_jsx(Route, { path: entry.path, element: React.createElement(entry.component) }, entry.path))) }) }) }));
};
const AuthenticatedRoutes = () => {
    return (_jsx(AuthProvider, { children: _jsx(Routes, { children: [
                ...ExternalAuthenticatedRoutes.map((entry) => (_jsx(Route, { path: entry.path + "/*", element: React.createElement(entry.component) }, entry.path))),
                _jsx(Route, { path: "*", element: _jsx(Portal, {}) }, "portal"),
            ] }) }));
};
const RootPage = () => {
    return (_jsx(Routes, { children: [
            _jsx(Route, { path: "/login/:app/*", element: _jsx(AuthPages, {}) }, "authentication"),
            ...AnonRoutes.map((entry) => (_jsx(Route, { path: entry.path + "/*", element: React.createElement(entry.component) }, entry.path))),
            _jsx(Route, { path: "*", element: _jsx(AuthenticatedRoutes, {}) }, "authenticated"),
        ] }));
};
export default React.memo(RootPage);
