var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _a, _b;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useState, } from "react";
import { Box, Container, createTheme, Grid, Link, Paper, ThemeProvider, Typography, } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { deepAssign, GroupBox, isPlainObject, Loader, showErrorDialog, useDialogContext, useLocation, useNavigate, useParams, } from "components-care";
import { useQuery } from "react-query";
import BackendHttpClient from "../../../components-care/connectors/BackendHttpClient";
import AuthMode from "components-care/dist/backend-integration/Connector/AuthMode";
import { Trans, useTranslation } from "react-i18next";
import { marked } from "marked";
import SocialLogins, { enableSocialLogins } from "./SocialLogins";
import PolicyViewer from "../../../components/PolicyViewer";
import LangSelector from "./LangSelector";
const useStyles = makeStyles()((theme) => ({
    root: {
        position: "absolute",
        backgroundColor: theme.palette.background.default,
        width: "100%",
        minHeight: "100%",
    },
    gridContainer: {
        minHeight: "100vh",
    },
    container: {
        height: "100%",
    },
    appInfo: {
        width: "100%",
    },
    footer: {
        backgroundColor: theme.palette.background.paper,
        width: "100%",
    },
    companyLogo: {
        maxWidth: "100%",
        width: "auto",
        height: "1.5rem",
        objectFit: "contain",
    },
    appIcon: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
    },
    langSelector: {
        left: "50%",
        transform: "translateX(-50%)",
    },
}));
const useGroupBoxStyles = makeStyles()((theme) => ({
    root: {
        borderColor: theme.palette.divider,
    },
}));
export var AuthFactorType;
(function (AuthFactorType) {
    /**
     * Classic password
     */
    AuthFactorType["PASSWORD"] = "password";
    /**
     * Time-based one time password
     */
    AuthFactorType["TOPT"] = "topt";
    /**
     * Universal 2nd Factor => WebAuthn
     */
    AuthFactorType["U2F"] = "u2f";
    /**
     * managed account, used to show redirect screen
     */
    AuthFactorType["EXTERNAL_OAUTH"] = "ext_oauth";
})(AuthFactorType || (AuthFactorType = {}));
const AuthPageStateContext = React.createContext(null);
export const useAuthPageState = () => {
    const ctx = useContext(AuthPageStateContext);
    if (!ctx)
        throw new Error("Context not set");
    return ctx;
};
const AuthPageAppInfoContext = React.createContext(null);
export const useAuthPageAppInfo = () => {
    const ctx = useContext(AuthPageAppInfoContext);
    if (!ctx)
        throw new Error("Context not set");
    return ctx;
};
const CurrentProviderConfig = {
    legalName: (_a = process.env.REACT_APP_PROVIDER_LEGAL_NAME) !== null && _a !== void 0 ? _a : "REACT_APP_PROVIDER_LEGAL_NAME not configured!",
    logo: process.env.REACT_APP_PROVIDER_LOGO_URL || null,
    imprintUrl: (_b = process.env.REACT_APP_PROVIDER_IMPRINT_URL) !== null && _b !== void 0 ? _b : 
    // eslint-disable-next-line no-script-url
    "javascript:alert('REACT_APP_PROVIDER_IMPRINT_URL not configured!')",
};
const AuthPageLayoutInner = (props) => {
    const { classes } = useStyles();
    const { classes: groupBoxClasses } = useGroupBoxStyles();
    const appInfo = useAuthPageAppInfo();
    const { app } = useParams();
    const { t, i18n } = useTranslation("auth");
    const [pushDialog] = useDialogContext();
    const navigate = useNavigate();
    const location = useLocation();
    // handle OAuth failure message
    useEffect(() => {
        const failureMessageId = "failure_message";
        const locationQuery = new URLSearchParams(location.search);
        const failureMessage = locationQuery.get(failureMessageId);
        if (!failureMessage)
            return;
        locationQuery.delete(failureMessageId);
        navigate(Object.assign(Object.assign({}, location), { search: locationQuery.toString() }));
        void showErrorDialog(pushDialog, failureMessage);
    }, [location, navigate, pushDialog]);
    const { data: appText } = useQuery(["app-info", appInfo.id], () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const contentRecord = appInfo.contents.find((entry) => entry.name === "app-info");
        if (!contentRecord)
            return "";
        const resp = yield BackendHttpClient.get(contentRecord.url, null, AuthMode.Off);
        const contents = resp.data.attributes.content_translations;
        return ((_a = contents[i18n.language.split("-")[0]]) !== null && _a !== void 0 ? _a : contents["en"] // fallback lang
        );
    }));
    const showPrivacyDialog = useCallback(() => {
        pushDialog(_jsx(PolicyViewer, { document: "tos-privacy" }));
    }, [pushDialog]);
    const statePack = useState({
        showSocialLogins: false,
        remainingFactors: [AuthFactorType.PASSWORD],
    });
    return (_jsx("div", { className: classes.root, children: _jsxs(Grid, { container: true, direction: "column", alignItems: "center", justifyContent: "space-between", alignContent: "stretch", wrap: "nowrap", className: classes.gridContainer, children: [_jsx(Grid, { item: true, xs: true, children: _jsx(Container, { maxWidth: "xs", className: classes.container, children: _jsx(Box, { my: 4, children: _jsxs(Grid, { container: true, direction: "column", alignItems: "center", justifyContent: "space-evenly", alignContent: "stretch", className: classes.container, spacing: 4, wrap: "nowrap", children: [_jsx(Grid, { item: true, xs: true, children: appInfo.image.medium && (_jsx("img", { src: appInfo.image.medium, alt: appInfo.full_name, className: classes.appIcon })) }), _jsxs(Grid, { item: true, children: [_jsx(LangSelector, { className: classes.langSelector }), _jsx(Paper, { children: _jsx(Box, { p: 4, children: _jsx(AuthPageStateContext.Provider, { value: statePack, children: props.children }) }) })] }), enableSocialLogins() && statePack[0].showSocialLogins && (_jsx(Grid, { item: true, className: classes.appInfo, children: _jsx(Box, { py: 2, children: _jsx(Paper, { children: _jsx(Box, { p: 4, children: _jsx(SocialLogins, { app: app !== null && app !== void 0 ? app : "undefined" }) }) }) }) })), appText && (_jsx(Grid, { item: true, className: classes.appInfo, children: _jsx(Box, { py: 2, children: _jsx(GroupBox, { label: "App Info", classes: groupBoxClasses, children: _jsx("div", { dangerouslySetInnerHTML: { __html: marked(appText) } }) }) }) })), _jsx(Grid, { item: true, xs: true })] }) }) }) }), _jsx(Grid, { item: true, className: classes.footer, children: _jsx(Container, { maxWidth: "xs", children: _jsx(Box, { py: 2, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { children: _jsx(Trans, { t: t, i18nKey: "footer.text", values: {
                                                    HOSTNAME: window.location.hostname,
                                                    PROVIDER: CurrentProviderConfig.legalName,
                                                }, components: {
                                                    "1": (_jsx(Link, { href: "https://github.com/Samedis-care/identity-management-frontend" })),
                                                    "2": _jsx(Link, { href: CurrentProviderConfig.imprintUrl }),
                                                } }) }) }), _jsxs(Grid, { item: true, xs: 12, container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 6, children: CurrentProviderConfig.logo && (_jsx("img", { src: CurrentProviderConfig.logo, alt: t("footer.logo-alt", {
                                                        PROVIDER: CurrentProviderConfig.legalName,
                                                    }), className: classes.companyLogo })) }), _jsx(Grid, { item: true, xs: 6, children: _jsx(Typography, { align: "right", children: _jsx(Link, { onClick: showPrivacyDialog, href: "#", children: t("footer.privacy") }) }) })] })] }) }) }) })] }) }));
};
const AuthPageLayout = (props) => {
    const { app } = useParams();
    const { data } = useQuery(["app-info", app], () => {
        return BackendHttpClient.get(`/api/v1/app/info/${app}`, null, AuthMode.Off);
    });
    if (!data)
        return _jsx(Loader, {});
    const _a = data.data.attributes.config.theme, { components_care: componentsCare } = _a, palette = __rest(_a, ["components_care"]);
    // this uses snake_case in backend, but we need camelCase
    const remapKeys = (obj) => Object.fromEntries(Object.entries(obj).map(([k, v]) => [
        k
            .toLowerCase()
            .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace("-", "").replace("_", "")),
        isPlainObject(v) ? remapKeys(v) : v,
    ]));
    const inner = {
        palette: palette,
        componentsCare: componentsCare ? remapKeys(componentsCare) : undefined,
    };
    if (!componentsCare)
        delete inner["componentsCare"];
    return (_jsx(AuthPageAppInfoContext.Provider, { value: data.data.attributes, children: _jsx(ThemeProvider, { theme: (outer) => createTheme(deepAssign({}, outer, inner)), children: _jsx(AuthPageLayoutInner, Object.assign({}, props)) }) }));
};
export default React.memo(AuthPageLayout);
