var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BackendError, RailsApiClient } from "components-care";
import AuthMode from "components-care/dist/backend-integration/Connector/AuthMode";
import { destroySession, handleAuth, } from "../../pages/components/AuthProvider";
import * as Sentry from "@sentry/react";
class BackendHttpClient extends RailsApiClient {
    constructor() {
        super(() => handleAuth(), (_response, responseData, method, url, args, body, auth) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f;
            const rsp = responseData;
            const success = (_b = (_a = rsp.meta) === null || _a === void 0 ? void 0 : _a.msg) === null || _b === void 0 ? void 0 : _b.success;
            const error = (_d = (_c = rsp.meta) === null || _c === void 0 ? void 0 : _c.msg) === null || _d === void 0 ? void 0 : _d.error;
            const message = (_f = (_e = rsp.meta) === null || _e === void 0 ? void 0 : _e.msg) === null || _f === void 0 ? void 0 : _f.message;
            if (!success) {
                if (error === "invalid_token" ||
                    error === "token_invalid" ||
                    error === "token_expired" ||
                    error === "otp_too_many_tries") {
                    if (auth === AuthMode.Off) {
                        throw new Error("Authentication is needed, but wasn't specified");
                    }
                    if (auth !== AuthMode.Try) {
                        yield destroySession();
                        // retry
                        return this.request(method, url, args, body, auth);
                    }
                }
                throw new BackendError(message || error || "Invalid response", error);
            }
            return responseData;
        }), undefined, undefined, (error) => {
            if (error.name === "NetworkError")
                return;
            if (error.name === "BackendError")
                return;
            Sentry.captureException(error);
        });
    }
}
export default new BackendHttpClient();
