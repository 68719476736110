var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useRef, useState, } from "react";
import supportedLanguages from "../assets/data/supported-languages.json";
import { deepEqual, PermissionContext } from "components-care";
import { useTranslation } from "react-i18next";
import { usePermissionContext } from "components-care/dist";
import i18n from "../i18n";
import MaintenancePage from "../components/maintenance/MaintenancePage";
export var MaintenanceType;
(function (MaintenanceType) {
    MaintenanceType["Off"] = "off";
    MaintenanceType["ReadOnly"] = "read-only";
    MaintenanceType["Full"] = "full";
})(MaintenanceType || (MaintenanceType = {}));
const MaintenanceInfoContext = React.createContext({
    current: null,
    planned: [],
});
const fetchInterval = 30000; // 30sec
const decodeMaintenanceEntry = (data) => {
    return Object.assign(Object.assign({}, data), { start: new Date(data.start), end: data.end ? new Date(data.end) : null });
};
export const getLocalizedReason = (data) => {
    if (i18n.language in data)
        return data[i18n.language];
    const lang = i18n.language.split("-")[0];
    if (lang in data)
        return data[lang];
    return data["en"];
};
export const MaintenanceModeProvider = (props) => {
    var _a, _b;
    const { children } = props;
    const { t } = useTranslation("common");
    const [perms, setPerms] = usePermissionContext();
    const fetchThread = useRef(-1);
    const maintenanceInfo = useRef(null);
    const [loadPromise, setLoadPromise] = useState(null);
    const [maintenanceInfoValue, setMaintenanceInfoValue] = useState(null);
    const fetchMaintenanceState = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (localStorage.NO_MAINTENANCE_MODE === "true") {
            maintenanceInfo.current = {
                current: null,
                planned: [],
            };
        }
        else {
            try {
                const resp = yield window.fetch("/api/v1/maintenance");
                const data = yield resp.json();
                maintenanceInfo.current = {
                    current: data.identity_management.current
                        ? decodeMaintenanceEntry(data.identity_management.current)
                        : null,
                    planned: data.identity_management.planned.map(decodeMaintenanceEntry),
                };
            }
            catch (e) {
                console.error(e);
                if (!maintenanceInfo.current) {
                    maintenanceInfo.current = {
                        current: {
                            start: new Date(),
                            end: null,
                            reason: Object.fromEntries(supportedLanguages.map((lang) => [
                                lang,
                                t("maintenance.fetch-fail-reason", { lng: lang }),
                            ])),
                            reason_long: {
                                en: "`" + e.message + "`",
                            },
                            type: MaintenanceType.Full,
                        },
                        planned: [],
                    };
                }
            }
        }
        setMaintenanceInfoValue((prev) => prev && deepEqual(prev, maintenanceInfo.current)
            ? prev
            : maintenanceInfo.current);
        return maintenanceInfo.current;
    }), [t]);
    const fetchHandler = useCallback(() => {
        setLoadPromise(fetchMaintenanceState());
    }, [fetchMaintenanceState]);
    useEffect(() => {
        fetchHandler();
        fetchThread.current = window.setInterval(fetchHandler, fetchInterval);
        return () => {
            window.clearInterval(fetchThread.current);
            fetchThread.current = -1;
        };
    }, [fetchHandler]);
    // suspend until data available
    if (!maintenanceInfoValue && loadPromise)
        throw loadPromise;
    if (!maintenanceInfoValue)
        return _jsx(React.Fragment, {});
    const currentMaintenanceType = (_b = (_a = maintenanceInfoValue.current) === null || _a === void 0 ? void 0 : _a.type) !== null && _b !== void 0 ? _b : MaintenanceType.Off;
    const permFilterPredicate = () => {
        switch (currentMaintenanceType) {
            case MaintenanceType.Off:
            case MaintenanceType.ReadOnly:
                return true;
            case MaintenanceType.Full:
                return false;
        }
    };
    const permMappingPredicate = (perm) => {
        switch (currentMaintenanceType) {
            case MaintenanceType.Off:
            case MaintenanceType.Full:
                return perm;
            case MaintenanceType.ReadOnly:
                return perm.endsWith(".reader") || perm.endsWith(".consumer")
                    ? perm
                    : perm + "-maintenance-disabled";
        }
    };
    const extraPerms = ["maintenance." + currentMaintenanceType];
    return (_jsx(MaintenanceInfoContext.Provider, { value: maintenanceInfoValue, children: _jsx(PermissionContext.Provider, { value: [
                perms
                    .filter(permFilterPredicate)
                    .map(permMappingPredicate)
                    .concat(extraPerms),
                setPerms,
            ], children: currentMaintenanceType === MaintenanceType.Full ? (_jsx(MaintenancePage, {})) : (children) }) }));
};
export const useMaintenanceInfo = () => {
    return useContext(MaintenanceInfoContext);
};
export default MaintenanceModeProvider;
