import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import { IconButton, ListItemText, Menu, MenuItem, styled, } from "@mui/material";
import { Translate } from "@mui/icons-material";
import supportedLanguages from "../../../assets/data/supported-languages.json";
import localeData from "components-care/dist/assets/data/locale-relevance.json";
import { ComponentsCareI18n, sortByLocaleRelevance } from "components-care";
import { useCCLanguagesTranslations } from "components-care/dist/utils/useCCTranslations";
const MenuWithBorder = styled(Menu)(({ theme }) => ({
    "& .MuiMenu-paper": {
        border: `1px solid ${theme.palette.divider}`,
    },
}));
const menuAnchorOrigin = {
    vertical: "bottom",
    horizontal: "center",
};
const menuTransformOrigin = {
    vertical: "top",
    horizontal: "center",
};
const langData = supportedLanguages
    .map((lang) => {
    var _a;
    const locales = Object.keys(localeData).filter((locale) => locale.startsWith(lang + "-"));
    locales.sort(sortByLocaleRelevance);
    return {
        lang: lang,
        locale: (_a = locales[0]) !== null && _a !== void 0 ? _a : lang,
    };
})
    .sort((a, b) => sortByLocaleRelevance(a.locale, b.locale));
const LangSelector = (props) => {
    const { className } = props;
    const [langMenuAnchor, setLangMenuAnchor] = useState(null);
    const { t } = useCCLanguagesTranslations();
    const onChangeLanguage = React.useCallback((evt) => {
        const newLocale = evt.currentTarget.attributes.getNamedItem("data-value").value;
        void ComponentsCareI18n.changeLanguage(newLocale);
        setLangMenuAnchor(null);
    }, [setLangMenuAnchor]);
    const handleFlagClick = React.useCallback((evt) => {
        setLangMenuAnchor(evt.currentTarget);
    }, [setLangMenuAnchor]);
    const handleMenuClose = React.useCallback(() => {
        setLangMenuAnchor(null);
    }, [setLangMenuAnchor]);
    return (_jsxs(_Fragment, { children: [_jsx(IconButton, { onClick: handleFlagClick, className: className, size: "large", children: _jsx(Translate, {}) }), _jsx(MenuWithBorder, { elevation: 0, anchorOrigin: menuAnchorOrigin, transformOrigin: menuTransformOrigin, anchorEl: langMenuAnchor, keepMounted: true, open: !!langMenuAnchor, onClose: handleMenuClose, children: langData.map((data) => {
                    return (_jsx(MenuItem, { "data-value": data.locale, onClick: onChangeLanguage, children: _jsx(ListItemText, { primary: t(data.lang) }) }, data.lang));
                }) })] }));
};
export default React.memo(LangSelector);
