import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
const BrowserCompatCheck = (props) => {
    const { t } = useTranslation("common");
    const errors = useMemo(() => {
        const err = [];
        if (!localStorage)
            err.push("browser-compat.local-storage");
        if (!sessionStorage)
            err.push("browser-compat.session-storage");
        return err;
    }, []);
    useEffect(() => {
        if (errors.length === 0)
            return;
        Sentry.captureMessage("Browser unsupported. Reasons: " + errors.join(", "));
    }, [errors]);
    if (errors.length > 0) {
        return (_jsx(Container, { maxWidth: "md", children: _jsx(Box, { p: 2, children: _jsx(Paper, { children: _jsx(Box, { p: 2, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 12, children: _jsx(Typography, { variant: "h1", children: t("browser-compat.title") }) }), _jsx(Grid, { item: true, xs: 12, children: _jsx("ul", { children: errors.map((err, idx) => (_jsx("li", { children: _jsx(Typography, { children: t(err) }) }, idx.toString(16)))) }) }), _jsx(Grid, { item: true, xs: 12, children: t("browser-compat.info") })] }) }) }) }) }));
    }
    return props.children;
};
export default React.memo(BrowserCompatCheck);
