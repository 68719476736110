var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Cookies from "js-cookie";
import BackendHttpClient from "../../components-care/connectors/BackendHttpClient";
import AuthMode from "components-care/dist/backend-integration/Connector/AuthMode";
import { FrameworkHistory, Loader, showInfoDialog, useDialogContext, usePermissionContext, useLocation, useNavigate, } from "components-care";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
export const ANONYMOUS_USER_ID = "anonymous";
const AuthProviderContext = React.createContext(undefined);
export const useAuthProviderContext = () => {
    const ctx = useContext(AuthProviderContext);
    if (!ctx)
        throw new Error("Auth Provider context not set");
    return ctx;
};
const AuthProviderResetContext = React.createContext(undefined);
export const useAuthProviderReset = () => {
    const ctx = useContext(AuthProviderResetContext);
    if (!ctx)
        throw new Error("Auth Provider context is not set");
    return ctx;
};
//
// Authentication Utils
//
class AuthError extends Error {
    constructor(msg) {
        super(msg);
        this.name = "AuthError";
    }
}
const parseUrlParams = () => new URLSearchParams(window.location.hash.replace("#", "?"));
export const getSession = () => parseUrlParams().get("token") ||
    sessionStorage.getItem("token") ||
    Cookies.get("token") ||
    localStorage.getItem("token") ||
    null;
export const getSessionExpire = () => {
    const expireStr = parseUrlParams().get("token_expire") ||
        sessionStorage.getItem("token_expire") ||
        Cookies.get("token_expire") ||
        localStorage.getItem("token_expire");
    if (!expireStr)
        return null;
    return new Date(/^[0-9]+$/.test(expireStr) ? parseInt(expireStr) : expireStr);
};
export const getSessionRefreshToken = () => parseUrlParams().get("refresh_token") ||
    sessionStorage.getItem("refresh_token") ||
    Cookies.get("refresh_token") ||
    localStorage.getItem("refresh_token") ||
    null;
export const destroySession = () => __awaiter(void 0, void 0, void 0, function* () {
    if (isSessionValid()) {
        // fire and forget
        try {
            yield BackendHttpClient.post("/api/v1/oauth/revoke", null, {
                token_type_hint: getSessionRefreshToken()
                    ? "access_token"
                    : undefined,
            }, AuthMode.Try);
        }
        catch (_e) {
            // ignored
        }
    }
    const errors = [];
    const search = parseUrlParams();
    ["token", "token_expire", "refresh_token"].forEach((key) => {
        Cookies.remove(key);
        if (Cookies.get(key))
            errors.push(`cookie ${key}`);
        delete localStorage[key];
        if (localStorage.getItem(key))
            errors.push(`localStorage ${key}`);
        delete sessionStorage[key];
        if (sessionStorage.getItem(key))
            errors.push(`sessionStorage ${key}`);
        search.delete(key);
    });
    const newHash = search.toString(); // also strip tokens from URL if present
    window.location.hash = newHash ? "#" + newHash : "";
    if (getSession())
        errors.push("getSession");
    if (getSessionExpire())
        errors.push("getSessionExpire");
    if (getSessionRefreshToken())
        errors.push("getSessionRefreshToken");
    if (errors.length > 0) {
        Sentry.captureException(new Error("destroySession failure"), {
            extra: {
                errors,
                href: window.location.href,
                cookies: Object.keys(Cookies.get()),
                localStorage: Object.keys(localStorage),
                sessionStorage: Object.keys(sessionStorage),
            },
        });
        throw new Error("Failed deleting session.");
    }
});
export const redirectToLogin = (includeRedirectURl = true) => {
    const redirectUrl = includeRedirectURl
        ? window.location.href
        : window.location.origin;
    const loginFail = redirectUrl.includes("/login/identity-management");
    FrameworkHistory.push({
        pathname: "/login/identity-management",
        search: `redirect_host=${encodeURIComponent(redirectUrl)}`,
        // key: Math.random().toString(),
    });
    if (loginFail)
        throw new Error("Login timeout, please try again");
};
export const isSessionValid = () => {
    const session = getSession();
    const sessionExpire = getSessionExpire();
    return !!(session && sessionExpire && sessionExpire > new Date());
};
let temporarilyDisableAutoRedirectToLogin = false;
export const handleAuth = () => __awaiter(void 0, void 0, void 0, function* () {
    const session = getSession();
    if (!isSessionValid()) {
        yield destroySession();
        if (!temporarilyDisableAutoRedirectToLogin) {
            redirectToLogin();
        }
        throw new AuthError("No authentication set");
    }
    return "Bearer " + session;
});
/**
 * Auth Provider for Ident.Services
 * @constructor
 */
const AuthProvider = (props) => {
    const { children, optional } = props;
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation("common");
    const [pushDialog] = useDialogContext();
    const [ctx, setCtx] = useState(undefined);
    const [, setPerms] = usePermissionContext();
    const resetAuthContext = useCallback(() => {
        setCtx(undefined);
    }, []);
    // handle /authenticated and enforce authentication
    useEffect(() => {
        if (ctx)
            return;
        (() => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            // handle authentication page
            if (location.pathname === "/authenticated") {
                // save auth params
                const search = parseUrlParams();
                navigate({
                    pathname: location.pathname,
                    search: location.search,
                    hash: "",
                }, { replace: true });
                const rememberMe = search.get("remember_me") === "true";
                const tokens = Object.fromEntries(["token", "refresh_token", "token_expire", "invite_token"].map((key) => [key, search.get(key)]));
                Object.entries(tokens)
                    .filter(([k]) => k !== "invite_token")
                    .forEach(([k, v]) => {
                    if (!v)
                        return;
                    sessionStorage.setItem(k, v);
                    Cookies.set(k, v);
                    if (rememberMe)
                        localStorage.setItem(k, v);
                });
                // accept invite
                const { invite_token } = tokens;
                if (invite_token) {
                    let tryAgain = true;
                    while (tryAgain) {
                        try {
                            yield BackendHttpClient.put(`/api/v1/user/invitations/${encodeURI(invite_token)}`, null, {});
                        }
                        catch (e) {
                            Sentry.captureException(e);
                            yield showInfoDialog(pushDialog, {
                                title: t("auth_provider.fail_accept_invite.title"),
                                message: e.message,
                                buttons: [
                                    {
                                        text: t("auth_provider.fail_accept_invite.retry"),
                                        autoFocus: true,
                                    },
                                    {
                                        text: t("auth_provider.fail_accept_invite.ignore"),
                                        onClick: () => {
                                            tryAgain = false;
                                        },
                                    },
                                ],
                            });
                        }
                        if (!tryAgain)
                            break;
                    }
                }
                navigate((_a = search.get("redirect_path")) !== null && _a !== void 0 ? _a : "/", { replace: true });
            }
            else {
                // special case: auth info passed in URL (e.g. my-profile)
                // save auth params in session storage and remove from URL
                const search = parseUrlParams();
                ["token", "refresh_token", "token_expire"].forEach((key) => {
                    const value = search.get(key);
                    if (value == null)
                        return;
                    sessionStorage.setItem(key, value);
                    search.delete(key);
                });
                const newHash = search.toString();
                navigate({
                    pathname: location.pathname,
                    search: location.search,
                    hash: newHash ? "#" + newHash : "",
                }, { replace: true });
            }
            // check for session
            if (!isSessionValid()) {
                if (optional) {
                    // set anonymous user
                    setCtx({
                        candos: [],
                        id: ANONYMOUS_USER_ID,
                        company: null,
                        department: null,
                        email: "anonymous@" + window.location.hostname,
                        gender: 0,
                        first_name: "Anonymous",
                        last_name: "User",
                        image: null,
                        job_title: null,
                        locale: null,
                        mobile: null,
                        personnel_number: null,
                        short: null,
                        tenants: [],
                        username: "anonymous@" + window.location.hostname,
                        updated_at: new Date(),
                    });
                }
                else {
                    yield destroySession();
                    redirectToLogin();
                }
            }
            else {
                // copy cookies to sessionStorage for faster access
                ["token", "token_expire", "refresh_token"]
                    .map((key) => { var _a; return [key, (_a = localStorage[key]) !== null && _a !== void 0 ? _a : Cookies.get(key)]; })
                    .filter(([key, value]) => value && !sessionStorage.getItem(key))
                    .forEach(([key, value]) => sessionStorage.setItem(key, value));
                // get user info
                try {
                    temporarilyDisableAutoRedirectToLogin = !!props.optional;
                    const userInfo = yield BackendHttpClient.get("/api/v1/identity-management/oauth/token/info", null);
                    setCtx(Object.assign(Object.assign({}, userInfo.data.attributes), { updated_at: new Date() }));
                }
                catch (e) {
                    const isInvalidToken = e.name === "BackendError" &&
                        e.code === "token_invalid";
                    console.error(e);
                    if (!props.optional && !isInvalidToken) {
                        yield showInfoDialog(pushDialog, {
                            title: t("auth_provider.fail_fetch_profile.title"),
                            message: e.message,
                            buttons: [
                                {
                                    text: t("auth_provider.fail_fetch_profile.retry"),
                                    autoFocus: true,
                                },
                            ],
                        });
                        window.location.reload();
                    }
                }
                finally {
                    temporarilyDisableAutoRedirectToLogin = false;
                }
            }
        }))();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ctx]);
    // set current user for sentry
    useEffect(() => {
        if (!ctx) {
            // not authenticated
            Sentry.setUser(null);
            setPerms([]);
        }
        else {
            Sentry.setUser({
                id: ctx.id,
            });
            setPerms(ctx.candos.map((e) => e.split("/")[1]));
        }
    }, [ctx, setPerms]);
    return ctx ? (_jsx(AuthProviderResetContext.Provider, { value: resetAuthContext, children: _jsx(AuthProviderContext.Provider, { value: ctx, children: children }) })) : (_jsx(Loader, {}));
};
export default React.memo(AuthProvider);
