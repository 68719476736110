import React from "react";
const AccountSelection = React.lazy(() => import("./Auth/AccountSelection"));
const AuthAddAccount = React.lazy(() => import("./Auth/AddAccount"));
const AuthAuthAccount = React.lazy(() => import("./Auth/AuthAccount"));
const AuthCreateAccount = React.lazy(() => import("./Auth/CreateAccount"));
const AuthForgotPassword = React.lazy(() => import("./Auth/ForgotPassword"));
const AuthResetPassword = React.lazy(() => import("./Auth/ResetPassword"));
const AuthConfirmAccount = React.lazy(() => import("./Auth/ConfirmAccount"));
const AuthConfirmRecoveryEmail = React.lazy(() => import("./Auth/ConfirmRecoveryEmail"));
const AuthRecoverAccount = React.lazy(() => import("./Auth/RecoverAccount"));
const DocumentViewerPage = React.lazy(() => import("./Standalone/DocumentViewerPage"));
const ProfilePageStandalone = React.lazy(() => import("./Profile/ProfilePageStandalone"));
export const AuthRoutes = [
    {
        path: "add-account",
        component: AuthAddAccount,
    },
    {
        path: "authenticate",
        component: AuthAuthAccount,
    },
    {
        path: "create-account",
        component: AuthCreateAccount,
    },
    {
        path: "confirm-account",
        component: AuthConfirmAccount,
    },
    {
        path: "forgot-password",
        component: AuthForgotPassword,
    },
    {
        path: "reset-password",
        component: AuthResetPassword,
    },
    {
        path: "confirm-recovery-email",
        component: AuthConfirmRecoveryEmail,
    },
    {
        path: "recover-account",
        component: AuthRecoverAccount,
    },
    {
        path: "*",
        component: AccountSelection,
    },
];
export const AnonRoutes = [
    {
        path: "/acceptance/:app/:name",
        component: DocumentViewerPage,
    },
];
export const ExternalAuthenticatedRoutes = [
    {
        path: "/my-profile",
        component: ProfilePageStandalone,
    },
];
