import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { useDialogContext } from "components-care";
import PolicyViewerContent from "./PolicyViewerContent";
const PolicyViewer = (props) => {
    const [, popDialog] = useDialogContext();
    return (_jsxs(Dialog, { open: true, onClose: popDialog, maxWidth: false, children: [_jsx(DialogContent, { children: _jsx(PolicyViewerContent, { document: props.document }) }), _jsx(DialogActions, { children: _jsx(Button, { onClick: popDialog, children: "Close" }) })] }));
};
export default React.memo(PolicyViewer);
